import { useMemo } from 'react';
import { Link } from '@appsumo/dorado-react';
import { DealSkuCardType } from '../types';
import clsx from 'clsx';

export function SkuCategory({
  deal,
  className,
}: {
  deal: DealSkuCardType;
  className?: string;
}) {
  const category = useMemo(() => {
    if (
      deal.taxonomy.subcategory &&
      deal.taxonomy.subcategory?.search_values?.length > 0
    ) {
      return {
        href: `/${[
          deal.taxonomy.group?.value_enumeration,
          deal.taxonomy.category?.value_enumeration,
          deal.taxonomy.subcategory?.value_enumeration,
        ].join('/')}/`,
        name: deal.taxonomy.subcategory.search_values[0],
      };
    }

    return null;
  }, [deal.taxonomy]);

  return (
    <>
      {category && (
        <span className={clsx('max-md:text-xs', className)}>
          in{' '}
          <Link href={category.href} className="relative z-1 underline">
            {category.name}
          </Link>
        </span>
      )}
    </>
  );
}
