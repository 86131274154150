import { FieldValues } from 'react-hook-form';
import * as Yup from 'yup';

export const signUpSchema: Yup.ObjectSchema<FieldValues> = Yup.object({
  email: Yup.string().email().required('A valid email is required'),
  password: Yup.string()
    .required('You must enter a password')
    .min(8, 'Your password must be at least 8 characters')
    .max(100),
  confirmation: Yup.string()
    .required('Please confirm your password')
    .oneOf([Yup.ref('password')], 'Your passwords must match'),
});

export const signInSchema: Yup.ObjectSchema<FieldValues> = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});

export const emailValidationSchema: Yup.ObjectSchema<FieldValues> = Yup.object({
  email: Yup.string().email().required(),
}).required();
