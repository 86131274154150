import { useState, useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { useRouter } from 'next/router';
import { useDebounce } from 'usehooks-ts';
import Cookies from 'universal-cookie';

import { SUBSCRIBE_URL } from '~/lib/util/constants';
import { isValidEmail } from '~/lib/util';
import { gtag } from '~/lib/util/dataLayer';
import useUser from '~/lib/user';
import { _createFormData, fetchEmailSubscribe } from '../emailSubscribe';

const HTTP_STATUS_205_MESSAGE =
  'The email address you entered used this coupon in a previous transaction. Limit one per email.';
const cookies = new Cookies();

export interface UseEmailSubscribeProps {
  customFormData?: {
    component?: string;
    deal_slug?: string;
    content?: string;
  };
  emailLabel?: string;
  enableGtagEvent?: boolean;
  errors?: { [key: number]: string };
  url?: string;
}

interface UseEmailSubscribeReturnProps {
  submitEmail: () => void;
  isEmailValid: boolean;
  isEmailSubmitted: boolean;
  hasEmailError: string;
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFormChangeEmail: (d: FieldValues) => void;
  email: string;
  isProcessing: boolean;
  createUser: (
    componentName: string,
    recaptcha_token?: string,
  ) => Promise<boolean>;
}

interface FetchJSONBody {
  email_subscribed: boolean;
}

export function useEmailSubscribe({
  emailLabel = 'email',
  enableGtagEvent = true,
  url = SUBSCRIBE_URL,
  ...props
}: UseEmailSubscribeProps): UseEmailSubscribeReturnProps {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const [hasEmailError, setHasEmailError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const debouncedEmail = useDebounce(email, 500);

  const { signUpEmail } = useUser();

  useEffect(() => {
    setIsEmailSubmitted(false);
    setHasEmailError('');

    setIsEmailValid(debouncedEmail ? isValidEmail(debouncedEmail) : true);
  }, [debouncedEmail]);

  function onChangeEmail(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function onFormChangeEmail(data: FieldValues) {
    setEmail(data.email);
  }

  function submitEmail(): void {
    if (typeof window === 'undefined') {
      return;
    }

    if (!email || !isEmailValid) {
      setHasEmailError('Please enter a valid email address.');
      return;
    }

    const formData = _createFormData(router.query, props.customFormData);
    formData.append(emailLabel, email);

    setIsProcessing(true);
    setHasEmailError('');

    fetchEmailSubscribe(url, formData, cookies.get('csrftoken') || '')
      .then(async (response: Response): Promise<FetchJSONBody | undefined> => {
        if (!response.ok) {
          setIsProcessing(false);
          throw new Error('Unable to submit your email, please try again.');
        }

        if (response.status === 205) {
          setIsProcessing(false);
          throw new Error(
            props.errors?.[response.status] || HTTP_STATUS_205_MESSAGE,
          );
        }

        // throw error if remaining response status is in errors object
        if (props.errors && Object.keys(props.errors).length === 0) {
          Object.entries(props.errors).forEach(([key, value]) => {
            if (response.status === Number(key)) {
              setIsProcessing(false);
              throw new Error(value);
            }
          });
        }

        try {
          const contentType = response.headers.get('content-type');

          if (contentType && contentType.indexOf('application/json') !== -1) {
            return await response.json();
          }
        } catch (e) {
          setIsProcessing(false);
          console.error('EmailSubscribe hook', e);
          throw new Error('Unable to validate email, please try again.');
        }
      })
      .then((body: FetchJSONBody | undefined) => {
        setIsEmailSubmitted(true);

        if (enableGtagEvent && body?.email_subscribed) {
          gtag('event', 'submit_email', {
            content: formData.get('content') || '',
            email: formData.get(emailLabel) || '',
          });
        }

        setIsProcessing(false);
      })
      .catch((error: Error) => {
        setIsProcessing(false);
        console.error('EmailSubscribe hook', error);
        setHasEmailError(`${error}`);
      });
  }

  async function createUser(
    componentName: string,
    recaptcha_token?: string,
  ): Promise<boolean> {
    if (typeof window === 'undefined') {
      return false;
    }

    if (!email || !isEmailValid) {
      setHasEmailError('Please enter a valid email address.');
      return false;
    }

    const formData = _createFormData(router.query, props.customFormData);
    formData.append(emailLabel, email);
    formData.append('content', componentName); // gtag event
    formData.append('token', recaptcha_token ?? '');
    formData.append('auto_login_user', 'true'); // Backwards compatibility for Nuxt / Django

    setIsProcessing(true);
    setHasEmailError('');

    const { error, session } = await signUpEmail(formData);

    setIsProcessing(false);

    if (error || !session?.user) {
      setHasEmailError(
        error ??
          'Unable to login with this email.  Please refresh and try again.',
      );
      return false;
    }

    setIsEmailSubmitted(true);
    return true;
  }

  return {
    email,
    hasEmailError,
    isEmailSubmitted,
    isEmailValid,
    onChangeEmail,
    onFormChangeEmail,
    submitEmail,
    isProcessing,
    createUser,
  };
}
