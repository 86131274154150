import { memo } from 'react';

import { FOMO_QUANTITY_REMAINING } from '~/constants/product';
import { useLimitedQuantity } from '~/hooks/limitedQuantity';
import { DealSkuCardType } from './types';

export default memo(function SkuQuantityRemaining({
  deal,
  isMobile = false,
}: {
  deal: DealSkuCardType;
  isMobile?: boolean;
}) {
  const { quantityRemaining, percentClaimed } = useLimitedQuantity(deal);

  if (percentClaimed < 0) return null;

  return (
    <div
      className={`mx-auto mb-2 inline-block w-3/4 text-sm ${
        isMobile
          ? 'mb-2 inline-block md:hidden'
          : 'mt-4 hidden md:inline-grid lg:mx-0'
      }`}
    >
      <div
        className={`h-1 overflow-hidden rounded-full bg-gravel${
          isMobile ? '' : ' mb-2'
        }`}
      >
        <div
          className="h-full rounded-full bg-brick"
          style={{ width: `${percentClaimed}%` }}
        />
      </div>
      <span className={`flex items-center gap-1${isMobile ? '' : ' mx-auto'}`}>
        {quantityRemaining < FOMO_QUANTITY_REMAINING ? (
          <>
            <span className="font-bold">{quantityRemaining}</span>
            {deal.uses_limited_licensing ? 'license' : 'code'}
            {quantityRemaining !== 1 ? 's' : ''} remaining
          </>
        ) : (
          <>
            <span className="font-bold">{`${percentClaimed}%`}</span>
            {'claimed'}
          </>
        )}
      </span>
    </div>
  );
});
