export type SmartCollectionComponent = {
  id: number;
  backgroundImage: string | null;
  backgroundColor: string | null;
  ctaLink: string | null;
  ctaText: string | null;
  text: string | null;
  mobileBackgroundImage: string | null;
  promoImage: string | null;
  headerText: string | null;
  bylineText: string | null;
  invertDefaultColors: boolean;
};

export enum CollectionType {
  THEMATIC = 'thematic',
  SPOTLIGHT = 'spotlight',
  SPOTLIGHT_CALLOUT = 'spotlight_callout',
  SPOTLIGHT_CUSTOM = 'spotlight_custom',
  PRODUCT_CAROUSEL = 'product_carousel',
  COUNTDOWN = 'countdown',
  COUNTDOWN_CUSTOM = 'countdown_custom',
  SHOP_BY = 'shop_by',
  BIG_BOI = 'big_boi',
}

export enum ZoneType {
  PDP_TOP = 'pdp_top',
  BEFORE_TERMS = 'pdp_before_terms',
  BEFORE_QUESTIONS = 'pdp_before_questions',
  AFTER_QUESTIONS = 'pdp_after_questions',
}
