import { createContext } from 'react';
import { DealSkuCardType } from '~/components/sku';

interface SmartCollectionContextProps {
  deals: DealSkuCardType[];
  showLtdQuantitySku?: boolean;
}

export const SmartCollectionContext =
  createContext<SmartCollectionContextProps>({} as SmartCollectionContextProps);
