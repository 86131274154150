import { useMemo } from 'react';
import { Deal } from '~/types/deal';
import { DealSkuCardType } from '~/components/sku';

type LimitedQuantityResult = {
  quantityRemaining: number;
  percentClaimed: number;
};

export function useLimitedQuantity(
  deal: Deal | DealSkuCardType,
): LimitedQuantityResult {
  const totalQuantity = useMemo(() => {
    return deal.uses_codes ? deal.active_codes : deal.limited_licenses_quantity;
  }, [deal]);

  const quantityClaimed = useMemo(() => {
    return deal.uses_codes
      ? deal.active_codes - deal.codes_remaining
      : deal.limited_licenses_quantity - deal.limited_licenses_remaining;
  }, [deal]);

  const quantityRemaining = useMemo(() => {
    return deal.uses_codes
      ? deal.codes_remaining
      : deal.limited_licenses_remaining;
  }, [deal]);

  const percentClaimed = useMemo(() => {
    if (!totalQuantity) return -1;
    return Math.round((quantityClaimed / totalQuantity) * 100);
  }, [quantityClaimed, totalQuantity]);

  return {
    quantityRemaining,
    percentClaimed,
  };
}
