import { InView } from 'react-intersection-observer';

export function DealImpression({
  id,
  children,
  addImpression,
  className = '',
}: {
  id: number;
  addImpression: (dealId: number) => void;
  children?: React.ReactNode;
  className?: string;
}) {
  const inViewChange = (inView: boolean) => {
    if (inView) {
      addImpression(id);
    }
  };

  return (
    <InView
      className={`${className} ${!children ? 'absolute h-1 w-1' : ''}`}
      onChange={inViewChange}
    >
      {children}
    </InView>
  );
}
