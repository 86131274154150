import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import useUser from '~/lib/user';
import {
  BLACK_FRIDAY_CAMPAIGN,
  BLACK_FRIDAY_LAST_CALL_PARTICIPATION_SLUG,
  BLACK_FRIDAY_LAST_CALL_SLUG,
  BLACK_FRIDAY_PARTICIPATION_SLUG,
  BLACK_FRIDAY_SLUG,
  LAST_CALL_PARTICIPATION_SLUG,
  LAST_CALL_SLUG,
  SUMO_DAY_CAMPAIGN,
  SUMO_DAY_PARTICIPATION_SLUG,
  SUMO_DAY_SLUG,
} from '~/lib/campaigns/constants';

import { BannerTypes, DealCampaign } from './types';
import type { DealSkuCardType } from '~/components/sku/types';

dayjs.extend(relativeTime);

function useBannerText(bannerType: string, deal: DealSkuCardType): string {
  return useMemo(() => {
    switch (bannerType) {
      case 'coming_soon':
        return 'Coming soon';
      case 'sold_out':
        return 'Sold out';
      case 'last_call':
        return '96 hour sale only';
      case 'black_friday':
        return '72 hour sale only';
      case 'ending_soon':
        return `Offer ends ${dayjs(deal.dates.end_date).fromNow()}`;
      case 'new':
        return 'New!';
      default:
        return '';
    }
  }, [bannerType, deal.dates.end_date]);
}

export function useBannerType(deal: DealSkuCardType): BannerTypes {
  const { session } = useUser();
  const [now] = useState(new Date());

  // prettier-ignore
  return useMemo(() => { // nosonar-next-line - complexity
    // Breaking this up into smaller functions is not worth it
    if ((deal.buy_button.is_coming_soon === true || (Array.isArray(deal.buy_button.is_coming_soon) && deal.buy_button.is_coming_soon[0] === true))
        && (deal.dates.start_date && (new Date(deal.dates.start_date) > new Date()))) {
      return 'coming_soon';
    }

    if (deal.browse_deal_status === 'expired') {
      return 'sold_out';
    }

    if (deal.buy_button.is_last_call) {
      // TODO: Fix TS type
      // @ts-ignore
      const campaigns = session?.campaigns || [];
      const participation = deal?.participation || [];

      // check each participation slug for 'last_call' substring and
      // each active campaign for 'last-call' substring
      if (participation.some(p => p.includes(LAST_CALL_PARTICIPATION_SLUG) || p.includes(LAST_CALL_SLUG)) &&
          campaigns.some(c => c.includes(LAST_CALL_SLUG))) {
        return 'last_call';
      }

      if (
        participation.includes(
          BLACK_FRIDAY_LAST_CALL_PARTICIPATION_SLUG,
        ) &&
        campaigns.includes(BLACK_FRIDAY_LAST_CALL_SLUG)
      ) {
        return 'black_friday';
      }
    }

    if (deal.dates.end_date && deal.has_started && deal.active_timer) {
      return 'ending_soon';
    }

    if (!deal.has_ended && deal.dates.start_date) {
      const startDate = new Date(deal.dates.start_date);
      const diff = (now.getTime() - startDate.getTime()) / 86400e3;

      if (diff >= 0 && diff <= 1) {
        return 'new';
      }
    }

    return '';
    // @ts-ignore
  }, [deal, session?.campaigns, now]);
}

function useBannerColor(bannerType: BannerTypes): string {
  return useMemo(() => {
    switch (bannerType) {
      case 'coming_soon':
        return 'bg-deep-ocean';
      case 'sold_out':
        return 'bg-brick';
      case 'last_call':
        return 'bg-ready-red';
      case 'black_friday':
        return 'bg-midnight';
      case 'ending_soon':
        return 'bg-ready-red-dark-20';
      case 'new':
        return 'bg-dollar-dark-20';
      default:
        return '';
    }
  }, [bannerType]);
}

export function useGetSkuBannerDetails(deal: DealSkuCardType) {
  const bannerType = useBannerType(deal);
  const bannerText = useBannerText(bannerType, deal);
  const bannerColor = useBannerColor(bannerType);

  return { bannerType, bannerText, bannerColor };
}

export function useGetCampaign(
  deal?: DealSkuCardType,
): DealCampaign | undefined {
  const { session } = useUser();

  return useMemo(() => {
    const campaigns = session?.campaigns || [];
    const participation = deal?.participation || [];

    if (
      campaigns.includes(BLACK_FRIDAY_SLUG) &&
      participation.includes(BLACK_FRIDAY_PARTICIPATION_SLUG)
    ) {
      return {
        text: 'Black Friday',
        type: BLACK_FRIDAY_CAMPAIGN,
      };
    }

    if (
      campaigns.includes(BLACK_FRIDAY_LAST_CALL_SLUG) &&
      participation.includes(BLACK_FRIDAY_LAST_CALL_PARTICIPATION_SLUG)
    ) {
      return {
        text: 'Black Friday',
        type: BLACK_FRIDAY_CAMPAIGN,
      };
    }

    if (
      campaigns.includes(SUMO_DAY_SLUG) &&
      participation.includes(SUMO_DAY_PARTICIPATION_SLUG)
    ) {
      return {
        text: 'Sumo day',
        type: SUMO_DAY_CAMPAIGN,
      };
    }
  }, [deal?.participation, session?.campaigns]);
}
