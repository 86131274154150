import { memo } from 'react';
import { Link } from '~/components/global/Link';
import { thousands } from '~/lib/format';

export const DealDownloads = memo(function DealDownloads({
  href,
  downloads,
  className = '',
  target = '_self',
}: {
  href: string;
  downloads: number;
  className?: string;
  target?: '_self' | '_blank';
}) {
  return (
    <div
      className={`mt-0.5 mb-2.5 flex items-center gap-3 text-xs font-bold ${
        className ? className : ''
      }`}
    >
      {downloads > 0 && <>Total Downloads: {thousands(downloads)}</>}
      {downloads === 0 && (
        <Link href={href} className="z-1" target={target}>
          Be the first to download!
        </Link>
      )}
    </div>
  );
});
