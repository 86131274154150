import { useMemo } from 'react';
import clsx from 'clsx';
import Image from 'next/image';
import appsumoSelect from '~/public/appsumo-select.svg';
import plusExclusive from '~/public/appsumo-plus-exclusive.svg';
import { DealListingType, DealSkuCardType } from '../types';

export function SkuBadge({
  deal,
  className,
}: {
  deal: DealSkuCardType;
  className?: string;
}) {
  const isSelect = useMemo(
    () => deal.listing_type === DealListingType.SELECT,
    [deal.listing_type],
  );
  const isPlusExclusive = useMemo(
    () => deal.listing_type === DealListingType.PLUS_EXCLUSIVE,
    [deal.listing_type],
  );

  return (
    <>
      {isSelect && (
        <div
          className={clsx(
            'inline-flex h-[20px] w-fit max-w-[200px] rounded bg-[#eef9f1] px-2 py-1 max-md:order-1 max-md:mt-2 md:mb-1',
            className,
          )}
        >
          <Image
            src={appsumoSelect}
            alt="AppSumo Select"
            width={128}
            height={12}
            style={{
              filter:
                'invert(26%) sepia(89%) saturate(1914%) hue-rotate(126deg) brightness(102%) contrast(95%)',
            }}
          />
        </div>
      )}
      {isPlusExclusive && (
        <div
          className={clsx(
            'inline-flex h-[20px] w-fit max-w-[200px] rounded bg-[#feefec] px-2 py-1 max-md:order-1 max-md:mt-2 md:mb-1',
            className,
          )}
        >
          <Image
            src={plusExclusive}
            alt="AppSumo Plus Exclusive"
            width={184}
            height={12}
            style={{
              filter:
                'invert(42%) sepia(36%) saturate(3709%) hue-rotate(341deg) brightness(104%) contrast(101%)',
            }}
          />
        </div>
      )}
    </>
  );
}
