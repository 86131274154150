import { useContext, useMemo } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { GlobalContext } from '~/contexts/global';
import { useBannerType } from '~/lib/browse/hooks';
import { isExperimentalVariant } from '~/lib/util';
import {
  APPSUMO_PLUS_DEAL_ID,
  LTD_QUANTITY_SKU_EXPERIMENT_NAME,
} from '~/lib/util/constants';
import { DealSkuCardType } from './types';

import { Link } from '~/components/global/Link';
import { SmartCollectionContext } from '~/contexts/smart-collection';
import { DealDownloads } from '~/components/product/DealDownloads';
import { DealImage } from '~/components/product/DealImage';
import { DealPrice } from '~/components/product/DealPrice';
import { DealRating } from '~/components/product/DealRating';
import { SkuBadge } from './sku-components/SkuBadge';
import { SkuCategory } from './sku-components/SkuCategory';
import SkuQuantityRemaining from './SkuQuantityRemaining';

dayjs.extend(relativeTime);
/**
 * Default deal SKU card for browse and carousels.
 */
export function SkuCard({
  deal,
  priority = false,
  target = '_self',
  imgWidth = 432,
  imgHeight = 243,
}: Readonly<{
  /**
   * Deal object (JSON) from the esbrowse endpoint
   */
  deal: DealSkuCardType;
  /**
   * If true then this is the first card and will be prioritized for LCP
   */
  priority?: boolean;
  /**
   * Target for the link
   * @default '_self'
   */
  target?: '_self' | '_blank';
  /** The desired image width. */
  imgWidth?: number;
  /** The desired image heigth. */
  imgHeight?: number;
}>) {
  const { enrolledExperiments } = useContext(GlobalContext);
  const { showLtdQuantitySku } = useContext(SmartCollectionContext);
  const ltdQuantitySkuVariant =
    enrolledExperiments?.[LTD_QUANTITY_SKU_EXPERIMENT_NAME];

  const bannerType = useBannerType(deal) ?? '';
  const showPricing = !['coming_soon', 'sold_out'].includes(bannerType);

  const showQuantityRemaining = useMemo(() => {
    if (
      deal.id === APPSUMO_PLUS_DEAL_ID ||
      ['sold_out'].includes(bannerType) ||
      (!isExperimentalVariant(ltdQuantitySkuVariant) && !showLtdQuantitySku)
    )
      return false;

    if (deal.uses_codes) {
      return (
        !!deal.shows_codes_remaining &&
        !!deal.active_codes &&
        !!deal.codes_remaining &&
        deal.codes_remaining > 0 &&
        deal.codes_remaining <= deal.active_codes
      );
    } else if (deal.uses_limited_licensing) {
      return (
        !!deal.limited_licenses_quantity &&
        !!deal.limited_licenses_remaining &&
        deal.limited_licenses_remaining > 0 &&
        deal.limited_licenses_remaining <= deal.limited_licenses_quantity
      );
    }

    return false;
  }, [bannerType, deal, ltdQuantitySkuVariant, showLtdQuantitySku]);

  return (
    <div className="relative h-full">
      <Link
        href={`${deal.get_absolute_url}`}
        target={target}
        className="absolute h-full w-full text-[0px] after:pointer-events-auto after:absolute after:inset-0 after:z-[1] after:content-['']"
      >
        <span className="sr-only">{deal.public_name}</span>
      </Link>
      <div className="relative flex h-full flex-col rounded bg-white md:border md:border-gray-300 md:shadow-md ">
        <DealImage
          deal={deal}
          width={imgWidth}
          height={imgHeight}
          priority={priority}
          className="rounded-t"
          syncDimensions
        />
        <div className="flex grow flex-col pb-1 max-md:pt-2 md:p-4 md:text-center">
          <SkuBadge deal={deal} className="md:mx-auto" />
          <span className="overflow-hidden text-ellipsis whitespace-nowrap font-bold max-md:text-xs">
            {deal.public_name}
          </span>
          <SkuCategory deal={deal} />
          <div className="md:grow">
            <div
              className="my-1 line-clamp-3 max-md:text-xs md:text-center"
              dangerouslySetInnerHTML={{ __html: deal.card_description }}
            />
          </div>
          <div className="relative md:text-center">
            {deal.is_free && (
              <DealDownloads
                downloads={deal.freebie_downloads_total}
                href={`${deal.get_absolute_url}`}
                target={target}
                className="justify-start md:justify-center"
              />
            )}
            {!deal.is_free && (
              <DealRating
                averageRating={deal.deal_review?.average_rating}
                reviewCount={deal.deal_review?.review_count}
                className="mb-2 justify-start md:justify-center"
                href={`${deal.get_absolute_url}#reviews`}
                target={target}
              />
            )}
            {showQuantityRemaining && (
              <SkuQuantityRemaining deal={deal} isMobile />
            )}
            {showPricing && (
              <div className="font-medium md:text-2xl">
                <DealPrice deal={deal} />
              </div>
            )}
            {showQuantityRemaining && <SkuQuantityRemaining deal={deal} />}
          </div>
        </div>
      </div>
    </div>
  );
}
