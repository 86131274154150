import { Arrow } from '@egjs/flicking-plugins';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { AlignType, Heading, MoveType } from '@appsumo/dorado-react';

import { Link } from '~/components/global/Link';
import { DealSkuCardType } from '~/components/sku/types';
import SumoFlicking from '~/components/smartcollections/components/SumoFlicking';
import { SkuCard } from '~/components/sku/SkuCard';

const CarouselArrows = ({
  prevArrowClassName,
  nextArrowClassName,
}: {
  prevArrowClassName: string;
  nextArrowClassName: string;
}) => {
  return (
    <>
      <span
        className={`${prevArrowClassName} absolute top-40 left-1 z-2 hidden h-10 w-10 rounded-full bg-black-pearl text-gray-300 hover:cursor-pointer md:block lg:left-0 lg:ml-2`}
      >
        <span className="sr-only">Previous</span>
        <FontAwesomeIcon icon={faChevronLeft} className="mx-3 my-2 h-6" />
      </span>
      <span
        className={`${nextArrowClassName} absolute top-40 right-1 z-2 hidden h-10 w-10 rounded-full bg-black-pearl text-gray-300 hover:cursor-pointer md:block lg:right-0 lg:mr-2`}
      >
        <span className="sr-only">Next</span>
        <FontAwesomeIcon icon={faChevronRight} className="mx-3 my-2 h-6" />
      </span>
    </>
  );
};

export default function ProductCarousel({
  id,
  title,
  deals,
  href,
  isCrossSell = false,
  priority = false,
}: Readonly<{
  id: number;
  title?: string;
  deals: DealSkuCardType[];
  href?: string;
  isCrossSell?: boolean;
  priority?: boolean;
}>) {
  // Need ID's to differentiate the arrows for each spotlight since they are outside the Flicking component
  const nextArrowClassName = `flicking-next-spotlight-${id}`;
  const prevArrowClassName = `flicking-prev-spotlight-${id}`;
  let plugins = [] as Array<Arrow>;

  if (typeof window !== 'undefined') {
    plugins = [
      new Arrow({
        parentEl: document?.body,
        moveByViewportSize: true,
        prevElSelector: `.${prevArrowClassName}`,
        nextElSelector: `.${nextArrowClassName}`,
      }),
    ];
  }

  return (
    <section className="my-4">
      <div className="relative mx-auto w-full pl-4 sm:pl-8" key={id}>
        <div className="mb-2 flex items-center justify-between">
          <Link href={`${href}`}>
            <Heading.H3 className="mb-4 !text-2xl">
              {!title && isCrossSell ? (
                <>
                  <span className="text-dollar">Explore</span>&nbsp;
                  <span className="text-midnight">related deals</span>
                </>
              ) : (
                <>{title}</>
              )}
            </Heading.H3>
          </Link>
          <Link
            href={`${href}`}
            className="pr-4 text-sm text-blue-600 hover:underline sm:pr-8"
          >
            View all
          </Link>
        </div>
        <SumoFlicking
          flickingProps={{
            plugins,
            align: AlignType.PREV,
            circular: true,
            circularFallback: 'bound',
            moveType: MoveType.FREE_SCROLL,
          }}
        >
          {deals.map((deal, index) => (
            <div
              key={deal.id}
              className="mr-5 w-[90%] max-w-[300px] pb-2 max-sm:max-w-[267px]"
            >
              <SkuCard
                key={deal.id}
                deal={deal}
                priority={priority && index === 0} // Load first image in the carousel with priority
                imgWidth={387}
                imgHeight={218}
              />
            </div>
          ))}
        </SumoFlicking>
        <CarouselArrows
          prevArrowClassName={prevArrowClassName}
          nextArrowClassName={nextArrowClassName}
        />
      </div>
    </section>
  );
}
