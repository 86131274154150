import { memo, useMemo } from 'react';
import clsx from 'clsx';

import { currency } from '~/lib/format';
import { DealSkuCardType } from '~/components/sku';

/**
 * Render the price and original price of a deal.
 */
export const DealPrice = memo(function DealPrice({
  deal,
  isCallout = false,
}: {
  deal: DealSkuCardType;
  isCallout?: boolean;
}) {
  const priceSuffix = useMemo(() => {
    if (['annual', 'lifetime'].includes(deal.default_plan?.plan_type)) {
      return deal.default_plan?.plan_type;
    }

    return null;
  }, [deal.default_plan]);

  const priceClassName = clsx(
    'ml-2 text-sm font-normal line-through',
    !isCallout && 'text-grace',
  );

  return (
    <div>
      <span>
        {deal.price === 0 && 'FREE'}
        {deal.price > 0 && (
          <>
            {currency(deal.price)}
            {priceSuffix && (
              <span className="text-sm font-normal">/{priceSuffix}</span>
            )}
          </>
        )}
      </span>
      {deal.original_price > 0 && (
        <span className={priceClassName}>{currency(deal.original_price)}</span>
      )}
    </div>
  );
});
