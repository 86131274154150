import { useEffect, useRef, useState, ReactNode } from 'react';
import { Arrow, AutoPlay } from '@egjs/flicking-plugins';
import { useIntersectionObserver } from 'usehooks-ts';
import Flicking from '@egjs/react-flicking';
import type { FlickingOptions } from '@egjs/react-flicking';
import '@egjs/react-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/arrow.css';

interface FlickingOptionProps extends Partial<FlickingOptions> {
  plugins?: Array<Arrow | AutoPlay>;
}

/**
 * This component is a wrapper around Flicking component that will only render the Flicking component when it is in view.
 * This is to speed up the initial page load time by not rendering the Flicking component until it is needed.
 * The timeout is to reduce the LCP if the carousel is above the fold and the biggest element in the viewport is a carousel image.
 *
 * @param children - The children to render, should be FlickingPanel components <Array> and possibly sibling arrow controls via <ViewportSlot />
 * @param flickingProps - The props to pass to the Flicking component
 * @param forceFlicking - Force the Flicking component to render regardless if in view
 */
function SumoFlicking({
  children,
  flickingProps,
  forceFlicking = false,
}: Readonly<{
  flickingProps: FlickingOptionProps;
  children: ReactNode[];
  forceFlicking?: boolean;
}>) {
  const flickingRef = useRef<any>(null);
  const [loadFlicking, setLoadFlicking] = useState(forceFlicking);
  const containerRef = useRef<HTMLDivElement>(null);
  const container = useIntersectionObserver(containerRef, {});

  useEffect(() => {
    if (!loadFlicking && container?.isIntersecting) {
      setTimeout(() => {
        setLoadFlicking(true);
      }, 100);
    }
  }, [container?.isIntersecting, loadFlicking]);

  return (
    <div ref={containerRef}>
      {loadFlicking ? (
        <Flicking {...flickingProps} ref={flickingRef}>
          {children}
        </Flicking>
      ) : (
        <div className="flicking-viewport">
          <div className="flicking-camera">
            {children?.[0] ? children[0] : children}
          </div>
          {children[1] ?? null}
        </div>
      )}
    </div>
  );
}

export default SumoFlicking;
