import { Campaign, CampaignsMap } from '~/types/campaign';

export const getActiveCampaignForDeal = ({
  campaignConfigs,
  dealParticipation,
}: {
  campaignConfigs: CampaignsMap;
  dealParticipation: string[];
}): Campaign | null => {
  if (!dealParticipation || !campaignConfigs) return null;

  const campaignSlugs = Object.keys(campaignConfigs || {});
  // returns first match of an auto campaign this deal participates in
  const campaignConfig = campaignSlugs.find((slug) =>
    dealParticipation.includes(campaignConfigs[slug].optin_slug),
  );

  return campaignConfig ? campaignConfigs[campaignConfig] : null;
};
