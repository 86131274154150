import clsx from 'clsx';
import { memo } from 'react';
import { TacoRating } from '@appsumo/dorado-react';
import { Link } from '@appsumo/dorado-react';

type TacoRatingSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export const DealRating = memo(function DealRating({
  href,
  averageRating,
  reviewCount,
  className,
  target = '_self',
  isAggregatePage = false,
  size = 'md',
}: {
  href: string;
  averageRating?: number;
  reviewCount?: number;
  className?: string;
  target?: '_self' | '_blank';
  isAggregatePage?: boolean; // I think we need to remove this? doesn't seem necessary to remove a link
  size?: TacoRatingSize;
}) {
  const showRating =
    !!averageRating && averageRating >= 0 && !!reviewCount && reviewCount > 0;
  return (
    <div
      className={`flex h-6 items-center gap-3 ${className ? className : ''}`}
    >
      {showRating && (
        <>
          {/* rating without link - e.g. mobile aggregate page */}
          {isAggregatePage && (
            <span className="lg:hidden">
              <TacoRating
                averageRating={averageRating}
                reviewCount={reviewCount.toString()}
                size={size}
                className="z-2"
                linkClassName="z-2 whitespace-nowrap"
              />
            </span>
          )}
          {/* rating with link */}
          <span className={clsx({ 'hidden lg:block': isAggregatePage })}>
            <TacoRating
              href={href}
              averageRating={averageRating}
              reviewCount={reviewCount.toString()}
              size={size}
              className="z-2"
              linkClassName="z-2 whitespace-nowrap"
            />
          </span>
        </>
      )}
      {reviewCount === 0 && (
        <div className="text-sm">
          No reviews yet,{' '}
          <Link href={href} className="relative z-2" target={target} styled>
            be the first!
          </Link>
        </div>
      )}
    </div>
  );
});
