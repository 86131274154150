import { CSSProperties, memo, useContext, useMemo } from 'react';
import Image from 'next/image';
import { DealSkuCardType } from '~/components/sku';
import { useGetSkuBannerDetails } from '~/lib/browse/hooks';
import { clsx } from 'clsx';

import { GlobalContext } from '~/contexts/global';
import { DealImpression } from '~/components/global/DealImpression';
import { getActiveCampaignForDeal } from '~/lib/campaigns/utils';
import { Campaign } from '~/types/campaign';

function SkuBanner({ deal }: { deal: DealSkuCardType }) {
  const { bannerText, bannerColor } = useGetSkuBannerDetails(deal);

  return (
    <div
      className={`absolute bottom-0 left-0 w-full ${bannerColor} text-center font-bold text-white`}
    >
      {bannerText}
    </div>
  );
}

/**
 * Render the image and associated banners of a deal.
 */
export const DealImage = memo(function DealImage({
  deal,
  width,
  height,
  priority = false,
  className = '',
  isSpotlight = false,
  hideBanner = false,
  syncDimensions = false,
}: {
  deal: DealSkuCardType;
  width: number;
  height: number;
  priority: boolean;
  className?: string;
  isSpotlight?: boolean;
  hideBanner?: boolean;
  syncDimensions?: boolean;
}) {
  const {
    dealImpressions: { addDealImpression },
    campaigns: { campaignConfigs },
  } = useContext(GlobalContext);

  const attrs: any = {};

  // usually the first SKU card is priority for mobile LCP if it above the fold
  if (priority) {
    attrs.priority = true;
  }

  const activeAutoCampaign: Campaign | null = useMemo(
    () =>
      getActiveCampaignForDeal({
        campaignConfigs,
        dealParticipation: deal.participation,
      }),
    [deal, campaignConfigs],
  );

  const skuBadge: string | undefined =
    activeAutoCampaign?.campaign_config?.sku_logo;

  const bannerStyles = {} as CSSProperties;
  if (activeAutoCampaign) {
    bannerStyles.background = activeAutoCampaign.campaign_config.banner_color;
  }
  const ratioMultiplier = syncDimensions ? 1 : 2;

  return (
    <div className={isSpotlight ? 'relative h-full w-full' : 'relative'}>
      <DealImpression id={deal.id} addImpression={addDealImpression} />
      <Image
        src={`${deal.media_url}?width=${width * ratioMultiplier}&height=${
          height * ratioMultiplier
        }&aspect_ratio=16:9`}
        unoptimized
        width={width}
        height={height}
        alt={deal.public_name}
        className={clsx(
          'aspect-sku-card w-full object-cover max-md:rounded',
          className,
        )}
        {...attrs}
      />
      {!hideBanner && <SkuBanner deal={deal} />}
      {activeAutoCampaign && skuBadge && (
        <div
          className="absolute left-0 top-0 rounded-br rounded-tl"
          style={bannerStyles}
        >
          <Image
            src={`${skuBadge}?width=320`}
            alt="Campaign Logo"
            className="!lg:w-24 !relative m-1 !h-auto !w-16"
            width={64}
            unoptimized
          />
        </div>
      )}
    </div>
  );
});
