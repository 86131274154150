export type DealReviewType = {
  review_count?: number;
  average_rating?: number;
};

export type DealSkuDatesType = {
  start_date: string | null;
  end_date: string | null;
};

export type DealCommonFeaturesType = {
  feature: string;
  order: number;
  is_active: boolean;
};

export type DealUniquePlanType = [
  planType: string,
  planInterval: string | null,
  intervalCount: number | null,
];

export type DealBuyButtonType = {
  is_coming_soon?: boolean;
  is_last_call?: boolean;
};

export type DealTaxonomyType = {
  slug: string;
  search_values: string[];
  value_enumeration: string | undefined;
};

export enum DealListingType {
  ORIGINAL = 'original',
  SELECT = 'select',
  INFO = 'info',
  MARKETPLACE = 'marketplace',
  FREEBIE = 'freebie',
  PLUS_EXCLUSIVE = 'plus_exclusive',
}

export type DefaultPlanType = {
  id: number;
  plan_type: 'lifetime';
  price: number;
  quantity: number;
};

export type DealSkuCardType = {
  id: number;
  slug: string;
  public_name: string;
  card_description: string;
  featured_image_url?: string;
  get_absolute_url: string;
  media_url: string;
  freebie_downloads_total: number;
  price: number;
  original_price: number;
  is_marketplace: boolean;
  is_free: boolean;
  internal_tags: string[];
  deal_review: DealReviewType;
  dates: DealSkuDatesType;
  buy_button: DealBuyButtonType;
  unique_plan_types: DealUniquePlanType[];
  common_features: DealCommonFeaturesType[];
  browse_deal_status: string;
  shows_codes_remaining?: boolean;
  codes_remaining: number;
  has_started: boolean;
  has_ended: boolean;
  active_timer: boolean;
  participation: string[];
  attributes?: Record<string, string[] | undefined>;
  story_images: any;
  search_after: (string | number)[];
  taxonomy: Record<string, DealTaxonomyType>;
  listing_type: DealListingType;
  default_plan: DefaultPlanType;
  favicon?: string;
  rgb_background_color: string;
  has_user_voted: boolean;
  total_votes_count: number;
  reviews_summary?: string;
  bottom_header?: string;
  uses_limited_licensing: boolean;
  uses_codes: boolean;
  active_codes: number;
  limited_licenses_quantity: number;
  limited_licenses_remaining: number;
  snippet_seo?: string;
};

export type PerksSkuCardType = {
  deal: DealSkuCardType;
  actionLabel: string;
  actionUrl: string;
  target?: '_self' | '_blank';
  showReviews: boolean;
};
