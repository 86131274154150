export enum UserStaffMessageGroupTypes {
  ALL = 'all',
  PAID = 'paid',
  PAID_PLUS = 'paid_plus',
  PAID_NON_PLUS = 'paid_non_plus',
}
export enum UserStaffMessageLocations {
  TOP = 'top',
  MID = 'mid',
}

export const FOMO_QUANTITY_REMAINING = 200;
